import React, {useState, useEffect} from 'react';
import styled from 'styled-components';

// Import your logo images using the new path
import ArmaniLogo from '../constants/images/figures/armani_logo.svg';
import BurberryLogo from '../constants/images/figures/burberry_logo.svg';
import CoachLogo from '../constants/images/figures/coach_logo.svg';
import DgLogo from '../constants/images/figures/dg_logo.svg';
import FendiLogo from '../constants/images/figures/fendi_logo.svg';
import GucciLogo from '../constants/images/figures/gucci_logo.svg';
import JimmyChooLogo from '../constants/images/figures/jimmy_choo_logo.svg';
import LouisVuittonLogo from '../constants/images/figures/louis_vuitton_logo.svg';
import VersaceLogo from '../constants/images/figures/versace_logo.svg';
import { keyframes } from 'styled-components';
import HeadlineDisplay from './headlines_display';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;


const logoImages = [
    ArmaniLogo,
    BurberryLogo,
    CoachLogo,
    DgLogo,
    FendiLogo,
    GucciLogo,
    JimmyChooLogo,
    LouisVuittonLogo,
    VersaceLogo
  ];



const CircleContainer = styled.div`
  position: relative; // This is important for absolute children
  width: 600px; // Size of the large circle
  height: 600px; // Should be same as width for a perfect circle
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; // This centers the circle horizontally
  animation: ${spin} 30s linear infinite;

  @media (max-width: 768px) {
    display: flex;
    width: 300px; // Size of the large circle
    height: 300px;

  }
`;

const counterSpin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const Logo = styled.img`
  position: absolute;
  width: 125px; // Size of the logos
  height: 125px;
  border-radius: 50%;
  overflow: hidden;
  // Remove display, justify-content, align-items as they're not needed for absolute positioning
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${counterSpin} 30s linear infinite;

  @media (max-width: 768px) {
    display: flex;
    width: 60px; // Size of the large circle
    height: 60px;

  }
  
`;

const Headline = styled.div`
  animation: ${counterSpin} 30s linear infinite; 
  max-width: 350px;
`;

// Calculate the position for each logo around the circle
const getLogoPosition = (index: number, total: number, containerSize: number, logoSize: number) => {
  const angle = (index / total) * Math.PI * 2; // angle in radians
  const radius = containerSize / 2 - logoSize / 2; // Adjust radius to keep logos inside the container
  // Adjust positions to center logos within their space
  const left = radius * Math.cos(angle) + radius - logoSize / 2 + 'px';
  const top = radius * Math.sin(angle) + radius - logoSize / 2 + 'px';
  return { left, top };
};

const LogosAroundCircle: React.FC = () => {
  const [containerSize, setContainerSize] = useState(600);
  const [logoSize, setLogoSize] = useState(60);

  useEffect(() => {
    // Function to update state based on window width
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setContainerSize(300);
        setLogoSize(30);
      } else {
        setContainerSize(600);
        setLogoSize(60);
      }
    };

    // Set initial size
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Clean up
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const totalLogos = logoImages.length; // If you have a logo in the center, subtract 1
  return (
    <CircleContainer>
      {logoImages.map((logoSrc, index) => (
        <Logo
          key={index}
          src={logoSrc}
          alt={`Logo ${index + 1}`}
          style={getLogoPosition(index, totalLogos, containerSize, logoSize)}
        />
      ))}
      <Headline>
      <HeadlineDisplay/>
      </Headline>
    </CircleContainer>
  );
};

export default LogosAroundCircle;