import React, { useEffect, useState, FormEvent, useCallback } from 'react';
import { MediaScreenMobile, StandardHeadTxt, StandardHeaderText, StandardSmallText, StandardSubHeaderText } from '../constants/styles/text';
import { AlignHorizon, AlignVertical, ContainIcon, HorizontalLine, MediumBox, SmallBox } from '../constants/styles/basic';
import { CounterContainer, DigitBox, SearchBarContainer, SearchBarInput } from '../constants/styles/specific';
import ArrowRight from '../constants/images/figures/arrow-right-icon.svg';
import LogosAroundCircle from './image_carousel';
import GiveAway from '../constants/images/pro-bowl.png';
import GiftBox from '../constants/images/gift-box.png';
import './modules.css';
import TestimonialsGrid from './testimonials';
import { motion } from 'framer-motion';
import { FloatingButton, UpArrowIcon } from '../constants/floating-button';
import styled from 'styled-components';

//        <NewsList data={data?.items} />

const NewTxt = styled(StandardHeadTxt)`
  font-size: 1.5rem;

  @media (max-width: 768px) {
    font-size: 2rem!important;
}
`;

function MainSearchPage() {
  // Assuming newsdata should be data.items based on your API structure
  const [subscribers, setSubscribers] = useState(null);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);
    
    // Assuming you're using an input with the name 'email'
    const email = formData.get('email');
    
    // Define the animation variants for the subscribe section
    
    try {
      const response = await fetch('/.netlify/functions/subscribers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
  
      if (!response.ok) {
        // Handle response error
        throw new Error('Network response was not ok');
      }
  
      // Handle response success
      console.log('Form successfully submitted');
      // Add logic here to clear the form or show a success message
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };
  
  // State to control the visibility of the Back to Top button
  const [showBackToTop, setShowBackToTop] = useState(false);

  // Effect to add scroll listener
  useEffect(() => {
    const toggleVisibility = () => {
      // Get the position of your email sign-in section
      // You might need to adjust the selector based on your actual DOM
      const emailSection = document.querySelector('#email-section');
      if (emailSection) {
        const position = emailSection.getBoundingClientRect();
        // Set state based on the position
        setShowBackToTop(position.bottom < 0);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);


  const [shouldPoll, setShouldPoll] = useState(true);

  // Polling function
  const fetchSubscriberCount = useCallback(() => {
    fetch('/.netlify/functions/getSubscriber')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok.');
        }
        return response.json();
      })
      .then(data => {
        setSubscribers(data.subscriberCount);
        // If you want to stop polling at some point, you can set shouldPoll to false
        if (shouldPoll) {
          setTimeout(fetchSubscriberCount, 5000); // Poll every 5 seconds
          console.log(subscribers)
        }
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        setTimeout(fetchSubscriberCount, 5000); // Retry after 5 seconds even if there is an error
      });
  }, [shouldPoll, subscribers]);

  useEffect(() => {
    fetchSubscriberCount();
    
    // When the component unmounts, stop the polling
    return () => setShouldPoll(false);
  }, [fetchSubscriberCount]);
  

  const placeholder = 'Please enter your email@address.com';
  const subscribersLeft = 25000 - (subscribers || 0);
  const digits = subscribersLeft.toString().split('');

  const subscribeSectionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0 }
  };

  // Define the animation variants for the header text
  const headerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };


  return (
    <>
      <div className='container'>
        <AlignHorizon style={{justifyContent:'space-evenly'}}>
        <MediaScreenMobile>
        <AlignVertical > 
        <motion.div
                variants={headerVariants}
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true, amount: 0.5 }}
                transition={{ duration: 0.5 }}
              >
        <StandardHeaderText style={{ fontSize: '60px', color: '#008080'}}>Quiet Luxury
        <span style={{color: '#000'}}>Daily</span></StandardHeaderText>
        </motion.div>
        
        <StandardSubHeaderText> We track the latest in fashion and luxurious living. <br /> <span style={{color: '#000'}}>Catch up in just 3 minutes. <br /> Win an Exclusive Luxury Experience! Find out how.</span></StandardSubHeaderText>
        <SmallBox />
        <form
          style={{ width: '-webkit-fill-available' }}
          name="subscribeForm"
          method="POST"
          data-netlify="true"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="form-name" value="subscribeForm" />
          <SearchBarContainer>
            <SearchBarInput
              type="email"
              name="email"
              placeholder={placeholder}
              required
            />
            <motion.button
                type="submit"
                data-tip
                data-for="logoTooltip"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                style={{ backgroundColor: 'transparent', border: 'solid 0px transparent' }}
              >
      
              <ContainIcon src={ArrowRight} alt="Submit" />
            </motion.button>
          </SearchBarContainer>
        </form>
        <SmallBox />
                <motion.div
          animate={{ scale: [1, 1.05, 1] }}
          transition={{
            repeat: Infinity,
            repeatType: "mirror",
            duration: 1
          }}
        >
          <StandardSubHeaderText>Join Our Holiday Giveaway!</StandardSubHeaderText>
        </motion.div>
        <StandardSubHeaderText> <span style={{color:'#000'}}>Watch the Pros like a Pro!!! </span></StandardSubHeaderText>

        </AlignVertical> 
        </MediaScreenMobile>
        <AlignVertical>
          <LogosAroundCircle />
        </AlignVertical>
        </AlignHorizon>
        <MediumBox />
        <HorizontalLine />
        <MediumBox></MediumBox>
        <MediaScreenMobile>
        <StandardHeaderText style={{fontSize: '40px'}}>What Readers Are Saying?</StandardHeaderText>
        <TestimonialsGrid />
        </MediaScreenMobile>
        <HorizontalLine />
        <MediaScreenMobile>
        <AlignVertical>
        <StandardHeaderText style={{fontSize: '40px'}}>Holiday Giveaway</StandardHeaderText>
        <MediumBox />
        <AlignHorizon style={{gap: '5rem'}}>
          <img src={GiftBox} alt='giveaway img' style={{width: '25.0%', height: 'auto'}}/>
          <AlignVertical style={{margin: '0', gap: '2em'}}>
          <StandardHeadTxt> <NewTxt style={{color: '#008080'}}> TWO FREE TICKETS </NewTxt><br /> To Pro Bowl Games (February 3rd & 4th) & <br /> Mystery Fashion Box (RSVP $1200)</StandardHeadTxt>
          <motion.div
                    variants={subscribeSectionVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ staggerChildren: 0.25, delayChildren: 0.3 }}
                  >
                    <CounterContainer>
                      {digits.map((digit, index) => (
                        <DigitBox key={index}>{digit}</DigitBox>
                      ))}
                    </CounterContainer>
                  </motion.div>
          <StandardSubHeaderText>Help us reach 25,000 new subscribers before Feb. 2024</StandardSubHeaderText>
          <StandardSmallText style={{margin: '0'}}>*Giveaway will only occur after 25,000 new subscribers*</StandardSmallText>
          </AlignVertical>
          <img src={GiveAway} alt='giveaway img' style={{width: '25.0%', height: 'auto'}}/>
        </AlignHorizon>
        </AlignVertical>
        </MediaScreenMobile>
        
      </div>
      {showBackToTop && (
        <FloatingButton
          onClick={scrollToTop}
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
        >
          <UpArrowIcon src={ArrowRight} alt="Back to Top" />
        </FloatingButton>
      )}
    </>
  );
}

export default MainSearchPage;
