import React from 'react';
import styled from 'styled-components';
import { StandardPSubHeaderText, StandardParagraph } from '../constants/styles/text';
import { SmallBox } from '../constants/styles/basic';
import { motion } from 'framer-motion';
// Define the structure for your data
type Testimonial = {
  name: string;
  message: string;
};

// Sample data
const testimonials: Testimonial[] = [
    {
      name: 'Ava L.',
      message: 'Stunning visuals and insightful articles—truly the epitome of luxury in every word. The members-only giveaway was a delightful bonus!'
    },
    {
      name: 'James H.',
      message: 'Your content is a daily source of inspiration. Winning the exclusive giveaway book has been the highlight of my month!'
    },
    {
      name: 'Mia T.',
      message: 'Each post is a lesson in style. The giveaway prints are now framed and beautifying my office space!'
    },
    {
      name: 'Noah E.',
      message: 'I turn to your content for a taste of the high life. The giveaway content pack was incredibly valuable—thank you!'
    },
    {
      name: 'Emma W.',
      message: 'You capture the essence of quiet luxury perfectly. The special edition giveaway was a masterpiece!'
    },
    {
      name: 'Liam G.',
      message: 'The quality of your features is unmatched. Winning the curated giveaway selection has enriched my appreciation for fine details.'
    }
  ];
// Styled components
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.25);
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  blur: 5px;
`;

const Name = styled(StandardPSubHeaderText)`
  font-weight: bold;
  color: #008080;
`;

const Message = styled(StandardParagraph)`
  margin-top: 5px;
`;

// Motion variants for the card animation
const cardVariants = {
  offscreen: {
    y: 50,
    opacity: 0
  },
  onscreen: {
    y: 0,
    opacity: 1,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8
    }
  }
};

const TestimonialsGrid: React.FC = () => {
  return (
    <Grid>
      {testimonials.map((testimonial, index) => (
        <motion.div
          key={index}
          variants={cardVariants}
          initial="offscreen"
          whileInView="onscreen"
          viewport={{ once: true, amount: 0.8 }} // The component animates when 80% of it is in the viewport
          transition={{ delay: index * 0.1 }} // Stagger the animation of each card
        >
          <Card>
            <Name>{testimonial.name}</Name>
            <SmallBox />
            <Message>{testimonial.message}</Message>
          </Card>
        </motion.div>
      ))}
    </Grid>
  );
};

export default TestimonialsGrid;