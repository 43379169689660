import React from 'react';
import { lightTheme, darkTheme } from './themes'; 
import { ThemeProvider } from 'styled-components';
import { AppBackground } from './constants/styles/basic';
import Header from './constants/header';
import Footer from './constants/footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainSearchPage from './joinNewsletter/join-newsletter-stew';




function App() {
  const currentTheme = 'light';
  const theme = currentTheme === 'light' ? lightTheme : darkTheme;
  return (
    <ThemeProvider theme={theme}>
      <AppBackground>
        
        <Router>
        <Header />
        <Routes>
          <Route path="/" element={<MainSearchPage />} />
        </Routes>
        <Footer />
      </Router>
      </AppBackground>
    </ThemeProvider>
  );
}

export default App;
