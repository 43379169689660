import styled from 'styled-components';
import { motion } from 'framer-motion';
import { ContainIcon } from './styles/basic';

// Styled component for the floating action button
export const FloatingButton = styled(motion.div)`
  position: fixed;
  right: 20px; // Adjust as needed for desktop
  bottom: 20px; // Adjust as needed
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7); // Glass effect
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  cursor: pointer;

  // Adjust position for mobile
  @media (max-width: 768px) {
    right: 50%; // Center at the bottom on mobile
    transform: translateX(50%);
  }
`;

// Arrow icon rotated to point upwards
export const UpArrowIcon = styled(ContainIcon)`
  transform: rotate(-90deg); // Adjust the rotation as needed
`;

// ... Rest of your MainSearchPage component ...

