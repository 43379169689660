import React, { useState, useEffect } from 'react';
import { StandardSubHeaderText } from '../constants/styles/text';
import styled from 'styled-components';

// Headlines array
const headlines = [
  "Celine's New Collection: A Nod to Nostalgic Minimalism",
  "Toteme's Wardrobe Staples Redefine Modern Classics",
  "Tove's Elegance: The New Summer Must-Have",
  "Comme des Garçons: Redefining Sophistication with Contrast Trim",
  "Hermès: Timeless Luxury with the Leather Double Breasted Coat",
  "Margaret Howell: The Quintessence of Understated Quality",
  "Connolly: Blending High-End Auto Leathers with Stunning Menswear",
  "Anderson & Sheppard: Bespoke Tailoring Meets Refined Casualwear"
];

const Staktext = styled(StandardSubHeaderText) `
  @media (max-width: 768px) {
    max-width: 200px;
  }
`;

const HeadlineDisplay = () => {
  const [currentHeadline, setCurrentHeadline] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentHeadline(prevHeadline => (prevHeadline + 1) % headlines.length);
    }, 5000); // Change headline every 5 seconds

    return () => clearInterval(timer);
  }, []);

  return (
<a href={`https://news.lowkeylavish.com`} target="_blank" rel="noopener noreferrer">
      <Staktext>{headlines[currentHeadline]}</Staktext>
    </a>
  );
};

export default HeadlineDisplay;