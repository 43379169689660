import React from 'react';
import LogoImg from './images/new-logo.png';
import { Link } from 'react-router-dom';
import { AlignHorizonHeader, ContainIcon, SmallBox } from './styles/basic';
import { HeaderText} from './styles/text';



function Header() {


  return (
    <>
      <AlignHorizonHeader as="nav" style={{justifyContent:'space-between', margin: '1% 5%', flexDirection: 'column'}}>
        <AlignHorizonHeader>
        <Link to="/"> <ContainIcon src={LogoImg} style={{width: '50px', height: '50px'}}/> </Link>
        <HeaderText>Lowkey Lavish</HeaderText>
        </AlignHorizonHeader>
        <SmallBox />
      </AlignHorizonHeader>
      <SmallBox />
    </>
  );
}

export default Header;


