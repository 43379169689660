//import '@fontsource/nunito/300.css'; // Weight 300
//import '@fontsource/nunito/400.css'; // Weight 400
//import '@fontsource/nunito/700.css';
//import '@fontsource/oleo-script/700.css';
//import '@fontsource/oleo-script/400.css';
//import '@fontsource/eb-garamond/400.css';
import '@fontsource-variable/bodoni-moda';
import '@fontsource-variable/lora';

export const lightTheme = {
  colors: {
    primary: '#5D5D5D', // A deeper grey for a rich look
    secondary: '#434343', // A darker grey for contrast
    background: '#F7f7f7', // Dark grey background for depth
    text: '#00000', // Light grey for text to stand out on dark backgrounds
    buttonText: '#DAA520', // Gold for buttons to stand out elegantly
    subText: 'rgba(221, 221, 221, 0.80)', // Lighter grey for secondary text
    white: '#ffffff',
    black: '#000000',
    header: '#CCCCCC', // Light grey for headers to contrast with the dark background
    border: '#4a4a4a3f', // Dark borders for definition
    highlight: '#008080', // A muted gold for a luxurious highlight
    shadow: 'rgba(0, 0, 0, 0.3)', // Stronger shadow for depth
    error: '#D32F2F', // Deeper red for errors
    success: '#388E3C', // Rich green for success messages
    warning: '#F57C00', // Deep yellow/orange for warnings
},

  fonts: {
    primary: "'Bodoni Moda Variable', sans-serif",
    secondary: "'Lora Variable', sans-serif",
    size: {
      small: '0.5rem',
      medium: '.75rem',
      paragraph: '1rem',
      title: '1.4rem',
      section_heading: '1.5rem',
      sub_heading: '1.6rem',
      heading: '2.5rem',
    },
    weight: {
      light: 300,
      normal: 400,
      bold: 700,
    }
  },
  spacing: {
    xxxs: '1px',
    xxs: '2px',
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px',
    xxl: '48px',
    xxxl: '100px',
  },
  borderRadius: {
    small: '2px',
    medium: '4px',
    large: '8px',
  },
  shadows: {
    low: '2px 2px 5px rgba(0, 0, 0, 0.1), -2px -2px 5px rgba(255, 255, 255, 0.7)',
    medium: '4px 4px 10px rgba(0, 0, 0, 0.1), -4px -4px 10px rgba(255, 255, 255, 0.7)',
    high: '6px 6px 15px rgba(0, 0, 0, 0.1), -6px -6px 15px rgba(255, 255, 255, 0.7)',
  },
  breakpoints: {
    mobile: '320px',
    tablet: '768px',
    desktop: '1024px',
    largeDesktop: '1440px'
  },
  zIndex: {
    dropdown: 100,
    modal: 1000,
    tooltip: 1500,
  },
  animations: {
    fadeIn: '300ms ease-in',
    fadeOut: '300ms ease-out',
  },
  grid: {
    container: '1140px',
    gutter: '30px',
    columns: 12,
  },
  interactiveStates: {
    button: {
      hover: 'background-color: #3e3e3e;', 
      active: 'background-color: #2a2a2a;', 
      pressed: 'box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.2);', 
    },
    link: {
      hover: 'text-decoration: underline;',
      active: 'color: #2a2a2a;', 
    },
    card: {
      hover: 'box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);',
      active: 'transform: scale(0.98);',
    },
  },
  transitions: {
    default: 'all 300ms ease-in-out',
    fadeIn: 'opacity 300ms ease-in',
    fadeOut: 'opacity 300ms ease-out',
    expand: 'height 300ms ease-in-out',
  },
  table: {
    header: {
      backgroundColor: '#eaeaea',
      textColor: '#333333',
    },
    cell: {
      backgroundColor: '#ffffff',
      borderColor: '#dddddd',
    },
    responsiveBreakpoint: '768px', 
  },
  list: {
    ordered: {
      markerType: 'decimal',
      markerColor: '#333333',
    },
    unordered: {
      markerType: 'disc',
      markerColor: '#333333',
    },
    itemSpacing: '8px',
  },
  scrollbar: {
    width: '8px',
    thumbColor: '#cccccc',
    trackColor: '#f0f0f0',
    hoverThumbColor: '#b0b0b0',
  },
  sizes: {
    height: {
      tiny: '5vh',   
      small: '10vh', 
      medium: '20vh', 
      large: '30vh', 
      full: '100vh',
    },
    width: {
      tiny: '10vw',   
      small: '20vw', 
      medium: '40vw', 
      large: '60vw', 
      full: '100vw', 
    },
    maxWidth: {
      full: '100vw',   // 100% of the viewport width
      sm: '600px',   // fixed pixel value
      md: '900px',   // fixed pixel value
      lg: '1200px',  // fixed pixel value
      xl: '1500px',  // fixed pixel value
    },
    minWidth: {
      xs: '10px',   // fixed pixel value
      sm: '30px',   // fixed pixel value
      md: '50px',   // fixed pixel value
      lg: '75px',   // fixed pixel value
      xl: '90px',   // fixed pixel value
    },
    maxHeight: {
      full: '100vh',   // 100% of the viewport height
      sm: '300px',   // fixed pixel value
      md: '500px',   // fixed pixel value
      lg: '700px',   // fixed pixel value
      xl: '900px',   // fixed pixel value
    },
    minHeight: {
      xs: '10px',   // fixed pixel value
      sm: '20px',   // fixed pixel value
      md: '40px',   // fixed pixel value
      lg: '60px',   // fixed pixel value
      xl: '80px',   // fixed pixel value
      xxl: '100px',
    },
  },
};
  
export const darkTheme = {
  colors: {
    primary: '#333333', // Dark grey for primary elements
    secondary: '#4a4a4a', // Slightly lighter grey for secondary elements
    background: '#121212', // Very dark grey, almost black for the background
    text: '#E0E0E0', // Light grey text for contrast against dark background
    buttonText: '#ffffff', // White for buttons to stand out
    subText: 'rgba(224, 224, 224, 0.80)', // Subtle grey for secondary text
    white: '#ffffff', // White remains the same
    header: '#cccccc', // Lighter grey for headers for contrast
    border: '#2c2c2c', // Slightly lighter border color for subtle differentiation
    highlight: '#F8E71C', // Highlight color can remain the same or be adjusted
    shadow: 'rgba(0, 0, 0, 0.5)', // Darker shadow for a deeper effect in dark mode
    error: '#FF6B6B', // Soft red for errors
    success: '#6BCF63', // Soft green for success
    warning: '#FFC260', // Soft yellow for warnings
  },
  fonts: lightTheme.fonts,
  breakpoints: lightTheme.breakpoints,
  spacing: lightTheme.spacing,
  shadows: lightTheme.shadows,
  borderRadius: lightTheme.borderRadius,
  zIndex: lightTheme.zIndex,
  animations: lightTheme.animations,
  grid: lightTheme.grid,
  interactiveStates: lightTheme.interactiveStates,
  transitions: lightTheme.transitions,
  table: lightTheme.table,
  list: lightTheme.list,
  scrollbar: lightTheme.scrollbar,
  sizes: lightTheme.sizes,
};
