import React from 'react';
import LogoImg from './images/new-logo.png';
import { AlignVertical, ContainIcon, SmallBox } from './styles/basic';





const Footer = () => {
  return (
    <>
    <AlignVertical style={{paddingTop: '15px'}}>
      <ContainIcon src={LogoImg} alt="Logo" style={{width: '50px', height: '50px'}}/>
      <SmallBox />
    </AlignVertical>
    </>
  );
};

export default Footer;
